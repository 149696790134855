import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

import Card from '../components/Card';
import LayoutAuth from '../components/LayoutAuth';
import TextContent from '../components/TextContent';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Button from '../components/Button';

import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_PRODUCTS } from '../graph/payment';
import { GET_CURRENCY } from '../graph/currency';
import { GET_USER } from '../graph/auth';

import {
  extractPrismicString,
  extractPrismicContent,
  hasContent,
} from '../utils/prismic';
import formatCurrency from '../utils/formatCurrency';
import getValue from '../utils/getValue';

const Actions = styled.div`
  margin-top: auto;
`;

function Plans() {
  const { data, loading } = useQuery(GET_PRODUCTS, {
    variables: { type: 'subscription' },
  });

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const { data: userData } = useQuery(GET_USER, { fetchPolicy: 'cache-only' });

  const { data: currencyData } = useQuery(GET_CURRENCY);

  const {
    title,
    text,
    left_section_header: leftSectionTitle,
    right_section_header: rightSectionTitle,
    left_section_content: leftSectionContent,
    left_section_content_1: leftSectionContent1,
    left_section_content_2: leftSectionContent2,
    left_section_content_3: leftSectionContent3,
    right_section_content: rightSectionContent,
  } = cmsData?.cmsContent?.plans || {};

  const handleTrackFreePlan = () => {
    if (!window?.gtag) return;

    window.gtag('event', 'onboarding', {
      send_to: 'UA-170315663-1',
      event_category: 'onboarding-plans',
      event_label: 'Free',
    });

    (window.dataLayer || []).push({
      event: 'signup',
      plan: 'free',
      userID: userData?.user?.id,
    });
  };

  const handleTrackPremiumPlan = () => {
    if (!window?.gtag) return;

    window.gtag('event', 'onboarding', {
      send_to: 'UA-170315663-1',
      event_category: 'onboarding-plans',
      event_label: 'Premium',
    });

    (window.dataLayer || []).push({
      event: 'signup',
      plan: 'premium',
      userID: userData?.user?.id,
    });
  };

  return (
    <LayoutAuth title="Complete Profile" loading={loading} large darker>
      <TextContent center white>
        <h1>{extractPrismicString(title)}</h1>
        {extractPrismicContent(text)}
      </TextContent>

      <Grid>
        <GridItem width={50} grow>
          <Card
            header={extractPrismicString(rightSectionTitle)}
            noMargin
            fixedHeightHeader
          >
            <TextContent smallMargin noMarginTop small marginBottom>
              {extractPrismicContent(rightSectionContent)}
            </TextContent>

            <Actions>
              <Button secondary to="/activities" onClick={handleTrackFreePlan}>
                Continue for Free
              </Button>
            </Actions>
          </Card>
        </GridItem>

        <GridItem width={50} grow>
          <Card
            header={extractPrismicString(leftSectionTitle)}
            noMargin
            fixedHeightHeader
          >
            <TextContent smallMargin noMarginTop small marginBottom>
              <p>
                {extractPrismicString(leftSectionContent1)} <br />
                {hasContent(leftSectionContent2) && (
                  <>
                    {extractPrismicString(leftSectionContent2)} <br />
                  </>
                )}
                {extractPrismicString(leftSectionContent3)}{' '}
                <strong>
                  {formatCurrency(
                    getValue(
                      data?.products?.[0],
                      currencyData?.currency || 'GBP',
                    ),
                    currencyData?.currency || 'GBP',
                  )}
                  {data?.products?.[0]?.chargeVAT ? ' + VAT' : ''}
                </strong>
              </p>
              {extractPrismicContent(leftSectionContent)}
            </TextContent>

            <Actions>
              <Button to="/onboarding/payment" onClick={handleTrackPremiumPlan}>
                Upgrade to Premium
              </Button>
            </Actions>
          </Card>
        </GridItem>
      </Grid>
    </LayoutAuth>
  );
}

export default Plans;
