import React, { useState } from 'react';
import StripeScriptLoader from 'react-stripe-script-loader';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { useQuery } from '@apollo/react-hooks';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

import Card from '../components/Card';
import Button from '../components/Button';
import Loader from '../components/Loader';
import PaymentForm from '../components/PaymentForm';
import LayoutAuth from '../components/LayoutAuth';
import CardIcons from '../components/CardIcons';
import TextContent from '../components/TextContent';
import Grid from '../components/Grid';
import GridItem from '../components/GridItem';
import Profile from '../components/Profile';

import { GET_CMS_CONTENT } from '../graph/cms';
import { GET_PRODUCTS } from '../graph/payment';
import { GET_CURRENCY } from '../graph/currency';
import { GET_USER } from '../graph/auth';

import formatCurrency from '../utils/formatCurrency';
import getValue, { VAT_AMOUNT } from '../utils/getValue';
import { extractPrismicContent, extractPrismicString } from '../utils/prismic';

import { ReactComponent as TickIcon } from '../assets/tick-icon.svg';

const SuccessIcon = styled(TickIcon)`
  display: block;
  margin: 0 auto ${(props) => props.theme.spacing.midLarge};
  height: 50px;
  width: 50px;
  fill: ${(props) => props.theme.colors.green500};
`;

const SuccessContainer = styled.div`
  width: 100%;
`;

function PaymentProduct({ id }) {
  const [paymentComplete, setPaymentComplete] = useState(false);

  const {
    data: { currency },
  } = useQuery(GET_CURRENCY);

  const { data: userData } = useQuery(GET_USER, {
    fetchPolicy: 'cache-first',
  });

  const style = useSpring({
    opacity: paymentComplete ? 1 : 0,
    transform: paymentComplete ? 'translate(0, 0)' : 'translate(0, 50px)',
  });

  const { data, loading } = useQuery(GET_PRODUCTS, {
    variables: { type: 'product' },
    fetchPolicy: 'network-only',
  });

  const { data: cmsData } = useQuery(GET_CMS_CONTENT, {
    fetchPolicy: 'cache-only',
  });

  const product = data?.products?.find((item) => item.id === id);

  const {
    id: productId,
    title,
    sub_title: subTitle,
    long_description: longDescription,
    success_text: successText,
  } = cmsData?.cmsContent?.products?.find(
    (item) => item.id === product?.cmsId,
  ) || {};

  const isFree = product?.discountedAmount === 0;
  const hasDiscount = product?.discountedAmount !== null;
  const discountAmount = formatCurrency(
    getValue(product, currency) - getValue(product, currency, true),
    currency,
  );
  const formattedPrice = formatCurrency(getValue(product, currency), currency);
  const formattedDiscountedPrice = formatCurrency(
    getValue(product, currency, true),
    currency,
  );
  const priceWithDiscount = hasDiscount
    ? formattedDiscountedPrice
    : formattedPrice;

  const chargeVAT = !!data?.products?.[0]?.chargeVAT;

  const vatAmount = formatCurrency(
    getValue(product, currency, hasDiscount) * (chargeVAT ? VAT_AMOUNT : 0),
    currency,
  );

  const priceWithVAT = formatCurrency(
    getValue(product, currency, hasDiscount, chargeVAT),
    currency,
  );

  const handlePaymentSuccess = () => {
    (window.dataLayer || []).push({
      event: 'coaching-scheduled',
      type: product.name,
      price: priceWithDiscount,
      userID: userData?.user?.id,
    });

    setPaymentComplete(true);
  };

  const handleOpenCalendly = async (url) => {
    window.Calendly.initPopupWidget({
      url: `${url}?name=${cmsData?.user.name}&email=${cmsData?.user.email}&a4=${cmsData?.user.phoneNumber}`,
    });
  };

  const renderSideContent = () => {
    return (
      <>
        <TextContent redh6 smallMargin noMarginTop>
          <h6>{extractPrismicString(subTitle)}</h6>
          <h1>{extractPrismicString(title)}</h1>
        </TextContent>

        <TextContent>{extractPrismicContent(longDescription)}</TextContent>
      </>
    );
  };

  const renderPaymentForm = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <StripeScriptLoader
        uniqueId="stripe"
        script="https://js.stripe.com/v3/"
        loader={<Loader />}
      >
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
          <Elements>
            <PaymentForm
              onSuccess={handlePaymentSuccess}
              user={cmsData?.user?.user}
              productId={product?.id}
              price={formattedPrice}
              discountedPrice={priceWithDiscount}
              hasDiscount={hasDiscount}
              isFree={isFree}
              freeText="Schedule now"
              currency={currency}
              priceWithVAT={priceWithVAT}
              discountAmount={discountAmount}
              vatAmount={vatAmount}
              chargeVAT={chargeVAT}
            />
          </Elements>
        </StripeProvider>
      </StripeScriptLoader>
    );
  };

  if (paymentComplete) {
    return (
      <LayoutAuth title="Payment Successful" loading={loading} large>
        <animated.div style={style}>
          <Card>
            <SuccessContainer>
              <SuccessIcon />

              <TextContent center>
                <h1>
                  {isFree ? 'Thank you for your order' : 'Payment Successful'}
                </h1>

                {extractPrismicContent(successText)}

                <Grid>
                  {cmsData?.cmsContent?.coaches?.map(
                    ({
                      name,
                      text,
                      image,
                      thirty_minute_calendly_url: thirtyURL,
                      sixty_minute_calendly_url: sixtyURL,
                    }) => (
                      <GridItem width={50} alignTop>
                        <Profile
                          title={name}
                          text={text}
                          image={image?.url}
                          actionText="Schedule your time now"
                          actionOnClick={() => {
                            handleOpenCalendly(
                              productId === 'coaching30'
                                ? thirtyURL?.url
                                : sixtyURL.url,
                            );
                          }}
                        />
                      </GridItem>
                    ),
                  )}
                </Grid>

                <Button to="/" secondary>
                  Done
                </Button>
              </TextContent>
            </SuccessContainer>
          </Card>
        </animated.div>
      </LayoutAuth>
    );
  }

  return (
    <LayoutAuth title="Payment" large loading={loading}>
      <Card renderSideContent={renderSideContent}>{renderPaymentForm()}</Card>
      <CardIcons />
    </LayoutAuth>
  );
}

export default PaymentProduct;
